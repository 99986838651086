"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.staticImplements = void 0;
/* class decorator */
function staticImplements() {
    return function (constructor) {
        constructor;
    };
}
exports.staticImplements = staticImplements;
