"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function parseError(error) {
    var unwrapped = (error !== null && error !== void 0 ? error : Error('[Statsig] Error was empty'));
    var isError = unwrapped instanceof Error;
    var name = isError ? unwrapped.name : 'No Name';
    var message = isError ? unwrapped.message : undefined;
    var trace = isError ? unwrapped.stack : getDescription(unwrapped);
    return { name: name, message: message, trace: trace };
}
exports.default = parseError;
function getDescription(obj) {
    try {
        return JSON.stringify(obj);
    }
    catch (_a) {
        return '[Statsig] Failed to get string for error.';
    }
}
