"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigSDKOptions_1 = require("../StatsigSDKOptions");
var ConsoleLogger = /** @class */ (function () {
    function ConsoleLogger(level) {
        this.logLevel = level;
    }
    ConsoleLogger.prototype.info = function (message) {
        if (this.logLevel === StatsigSDKOptions_1.LogLevel.NONE) {
            return;
        }
        console.log(message);
    };
    ConsoleLogger.prototype.error = function (message) {
        if (this.logLevel !== StatsigSDKOptions_1.LogLevel.DEBUG) {
            return;
        }
        console.error(message);
    };
    return ConsoleLogger;
}());
exports.default = ConsoleLogger;
