"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Constants_1 = require("./Constants");
var StatsigLocalStorage = /** @class */ (function () {
    function StatsigLocalStorage() {
    }
    StatsigLocalStorage.getItem = function (key) {
        var _a;
        try {
            if (this.isStorageAccessible()) {
                return window.localStorage.getItem(key);
            }
        }
        catch (e) {
            // noop
        }
        return (_a = this.fallbackSessionCache[key]) !== null && _a !== void 0 ? _a : null;
    };
    StatsigLocalStorage.setItem = function (key, value) {
        try {
            if (this.isStorageAccessible()) {
                window.localStorage.setItem(key, value);
                return;
            }
        }
        catch (e) {
            // noop
        }
        this.fallbackSessionCache[key] = value;
    };
    StatsigLocalStorage.removeItem = function (key) {
        try {
            if (this.isStorageAccessible()) {
                window.localStorage.removeItem(key);
                return;
            }
        }
        catch (e) {
            // noop
        }
        delete this.fallbackSessionCache[key];
    };
    StatsigLocalStorage.cleanup = function () {
        try {
            if (this.isStorageAccessible(true) // clean up all storage keys if this session sets disabled
            ) {
                for (var key in window.localStorage) {
                    if (typeof window.localStorage[key] !== 'string') {
                        continue;
                    }
                    if (key == null) {
                        continue;
                    }
                    // if local storage is disabled on a subsequent session on this device,
                    // lets delete everything we already have stored in local storage
                    if (!this.disabled && key in Constants_1.LOCAL_STORAGE_KEYS) {
                        continue;
                    }
                    if (!this.disabled &&
                        key.substring(0, Constants_1.STORAGE_PREFIX.length) !== Constants_1.STORAGE_PREFIX) {
                        continue;
                    }
                    window.localStorage.removeItem(key);
                }
            }
        }
        catch (e) {
            // noop
        }
    };
    StatsigLocalStorage.isStorageAccessible = function (ignoreDisabledOption) {
        if (ignoreDisabledOption === void 0) { ignoreDisabledOption = false; }
        if (this.canAccessStorageAccessible == null) {
            this.canAccessStorageAccessible =
                typeof Storage !== 'undefined' &&
                    typeof window !== 'undefined' &&
                    window != null &&
                    window.localStorage != null;
        }
        var canAccess = this.canAccessStorageAccessible;
        if (ignoreDisabledOption) {
            return canAccess;
        }
        return !this.disabled && canAccess;
    };
    StatsigLocalStorage.disabled = false;
    StatsigLocalStorage.fallbackSessionCache = {};
    StatsigLocalStorage.canAccessStorageAccessible = null;
    return StatsigLocalStorage;
}());
exports.default = StatsigLocalStorage;
