"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigHooks_1 = require("./StatsigHooks");
/**
 * A synchronous hook to check the value of the gate.  To ensure correctness, wait for SDK initialization before
 * calling.
 * @param gateName - the name of the gate to check
 * @param ignoreOverrides - flag to ignore overrides
 * @returns a result indicating the boolean value of the gate and loading state of the SDK
 */
function default_1(gateName, ignoreOverrides) {
    return (0, StatsigHooks_1.useGateImpl)(gateName, {
        ignoreOverrides: ignoreOverrides,
    });
}
exports.default = default_1;
