"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigHooks_1 = require("./StatsigHooks");
/**
 * A synchronous hook to check the value of a Dynamic Config without exposure logging.
 * To ensure correctness, wait for SDK initialization before calling.
 * @param configName - the name of the DynamicConfig to check
 * @param options - Custom options for the API
 *
 * ignoreOverrides - flag to ignore overrides
 *
 * @returns a ConfigResult indicating the DynamicConfig value, and the loading state of the SDK
 */
function default_1(configName, options) {
    return (0, StatsigHooks_1.useConfigImpl)(configName, options, true /* exposure logging disabled */);
}
exports.default = default_1;
