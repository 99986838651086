"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.difference = exports.now = void 0;
function now(opts) {
    if (opts === void 0) { opts = { withPrecision: false }; }
    if (typeof performance === 'undefined' || !performance) {
        return Date.now();
    }
    if (!opts.withPrecision) {
        return performance.now() | 0;
    }
    return performance.now();
}
exports.now = now;
function difference(time) {
    return now() - time;
}
exports.difference = difference;
