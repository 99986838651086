"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvaluationReason = void 0;
var EvaluationReason;
(function (EvaluationReason) {
    EvaluationReason["Network"] = "Network";
    EvaluationReason["Bootstrap"] = "Bootstrap";
    EvaluationReason["InvalidBootstrap"] = "InvalidBootstrap";
    EvaluationReason["Cache"] = "Cache";
    EvaluationReason["Prefetch"] = "Prefetch";
    EvaluationReason["Sticky"] = "Sticky";
    EvaluationReason["LocalOverride"] = "LocalOverride";
    EvaluationReason["Unrecognized"] = "Unrecognized";
    EvaluationReason["Uninitialized"] = "Uninitialized";
    EvaluationReason["Error"] = "Error";
    EvaluationReason["NetworkNotModified"] = "NetworkNotModified";
    EvaluationReason["BootstrapStableIDMismatch"] = "BootstrapStableIDMismatch";
})(EvaluationReason = exports.EvaluationReason || (exports.EvaluationReason = {}));
