"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigHooks_1 = require("./StatsigHooks");
/**
 * A synchronous hook to check the value of a Dynamic Config.  To ensure correctness, wait for SDK initialization before
 * calling.
 * @param configName - the name of the DynamicConfig to check
 * @param ignoreOverrides - flag to ignore overrides
 * @returns a ConfigResult indicating the DynamicConfig value, and the loading state of the SDK
 */
function default_1(configName, ignoreOverrides) {
    return (0, StatsigHooks_1.useConfigImpl)(configName, {
        ignoreOverrides: ignoreOverrides,
    });
}
exports.default = default_1;
