"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiagnosticsImpl = void 0;
var Timing_1 = require("./Timing");
var DiagnosticsImpl = /** @class */ (function () {
    function DiagnosticsImpl(args) {
        var _this = this;
        var _a, _b, _c;
        this.mark = {
            overall: this.selectAction('overall'),
            initialize: this.selectStep('initialize'),
            bootstrap: this.selectStep('bootstrap'),
            api_call: function (tag) {
                switch (tag) {
                    case 'getConfig':
                        return _this.selectAction('get_config');
                    case 'getExperiment':
                        return _this.selectAction('get_experiment');
                    case 'checkGate':
                        return _this.selectAction('check_gate');
                    case 'getLayer':
                        return _this.selectAction('get_layer');
                }
                return null;
            },
        };
        this.context = 'initialize';
        this.defaultMaxMarkers = 30;
        this.maxMarkers = {
            initialize: this.defaultMaxMarkers,
            config_sync: this.defaultMaxMarkers,
            event_logging: this.defaultMaxMarkers,
            api_call: this.defaultMaxMarkers,
        };
        this.markers = (_a = args.markers) !== null && _a !== void 0 ? _a : {
            initialize: [],
            config_sync: [],
            event_logging: [],
            api_call: [],
        };
        this.disabled = (_c = (_b = args.options) === null || _b === void 0 ? void 0 : _b.getDisableDiagnosticsLogging()) !== null && _c !== void 0 ? _c : false;
    }
    DiagnosticsImpl.prototype.setContext = function (context) {
        this.context = context;
    };
    DiagnosticsImpl.prototype.selectAction = function (key, step) {
        var _this = this;
        return {
            start: function (data, context) {
                return _this.addMarker(__assign({ key: key, step: step, action: 'start', timestamp: (0, Timing_1.now)({ withPrecision: true }) }, (data !== null && data !== void 0 ? data : {})), context);
            },
            end: function (data, context) {
                return _this.addMarker(__assign({ key: key, step: step, action: 'end', timestamp: (0, Timing_1.now)({ withPrecision: true }) }, (data !== null && data !== void 0 ? data : {})), context);
            },
        };
    };
    DiagnosticsImpl.prototype.selectStep = function (key) {
        return {
            process: this.selectAction(key, 'process'),
            networkRequest: this.selectAction(key, 'network_request'),
        };
    };
    DiagnosticsImpl.prototype.addMarker = function (marker, overrideContext) {
        var _a;
        var context = overrideContext !== null && overrideContext !== void 0 ? overrideContext : this.context;
        if (this.disabled && context === 'api_call') {
            return false;
        }
        if (this.maxMarkers[context] !== undefined &&
            this.markers[context].length >=
                ((_a = this.maxMarkers[context]) !== null && _a !== void 0 ? _a : this.defaultMaxMarkers)) {
            return false;
        }
        this.markers[context].push(marker);
        return true;
    };
    DiagnosticsImpl.prototype.getMarkers = function (context) {
        return this.markers[context];
    };
    DiagnosticsImpl.prototype.setMaxMarkers = function (context, max) {
        this.maxMarkers[context] = max;
    };
    DiagnosticsImpl.prototype.getMarkerCount = function (context) {
        return this.markers[context].length;
    };
    DiagnosticsImpl.prototype.clearContext = function (context) {
        this.markers[context] = [];
    };
    return DiagnosticsImpl;
}());
exports.DiagnosticsImpl = DiagnosticsImpl;
var Diagnostics = /** @class */ (function () {
    function Diagnostics() {
    }
    Diagnostics.initialize = function (args) {
        this.instance = new DiagnosticsImpl(args);
        this.mark = this.instance.mark;
        this.disabled = this.instance.disabled;
        this.getMarkers = this.instance.getMarkers.bind(this.instance);
        this.getMarkerCount = this.instance.getMarkerCount.bind(this.instance);
        this.setMaxMarkers = this.instance.setMaxMarkers.bind(this.instance);
        this.setContext = this.instance.setContext.bind(this.instance);
        this.clearContext = this.instance.clearContext.bind(this.instance);
    };
    Diagnostics.formatError = function (e) {
        if (!(e && typeof e === 'object')) {
            return;
        }
        return {
            code: this.safeGetField(e, 'code'),
            name: this.safeGetField(e, 'name'),
            message: this.safeGetField(e, 'message'),
        };
    };
    Diagnostics.safeGetField = function (data, field) {
        if (field in data) {
            return data[field];
        }
        return undefined;
    };
    return Diagnostics;
}());
exports.default = Diagnostics;
