"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var LogEvent = /** @class */ (function () {
    function LogEvent(eventName) {
        this.user = null;
        this.value = null;
        this.metadata = null;
        this.eventName = eventName;
        this.statsigMetadata = {};
        this.time = Date.now();
    }
    LogEvent.prototype.getName = function () {
        return this.eventName;
    };
    LogEvent.prototype.setValue = function (value) {
        this.value = value;
    };
    LogEvent.prototype.setMetadata = function (metadata) {
        this.metadata = metadata;
    };
    LogEvent.prototype.addStatsigMetadata = function (key, value) {
        this.statsigMetadata[key] = value;
    };
    LogEvent.prototype.setUser = function (newUser) {
        // Need to remove private attributes from logs and also keep in the original user for evaluations.
        this.user = __assign({}, newUser);
        delete this.user.privateAttributes;
    };
    LogEvent.prototype.setSecondaryExposures = function (exposures) {
        if (exposures === void 0) { exposures = []; }
        this.secondaryExposures = exposures;
    };
    LogEvent.prototype.toJsonObject = function () {
        var _a;
        return {
            eventName: this.eventName,
            user: this.user,
            value: this.value,
            metadata: this.metadata,
            time: this.time,
            statsigMetadata: this.statsigMetadata,
            secondaryExposures: (_a = this.secondaryExposures) !== null && _a !== void 0 ? _a : undefined,
        };
    };
    return LogEvent;
}());
exports.default = LogEvent;
